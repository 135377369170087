<script setup>
import { storeToRefs } from 'pinia';
import Illustration1 from 'assets/illustrations/illu-01.svg?url';
// import { useForm } from 'vee-validate';
// import { useCenterStore } from '~/stores/centerDetails';
// import { trackUserClick } from '~/composables/useTracking';
import { useMessageStore } from '~/stores/messages';

// Fetch messages
const messagesStore = useMessageStore();
const { messages: staticKeys } = storeToRefs(messagesStore);

// Markdown
const { $md } = useNuxtApp();
const deleteIntro = staticKeys.value?.account_form_delete_feedback_intro ? $md.render(staticKeys.value?.account_form_delete_feedback_intro) : '';

/*
// Center store
const { centerUid, centerName } = storeToRefs(useCenterStore());

// Prepare form
const { values, meta, handleSubmit, isSubmitting } = useForm();

const isActive = computed(() => {
    if (!values) {
        return false;
    }
    return objContainsValues(values) && meta.value?.valid;
});

const textareaConfig = ref({
    label: 'Anmerkung einfügen',
    name: 'feedbackText',
    id: 'feedbackText'
});

const checkboxConfig = ref({
    reason1: {
        fieldType: 'checkbox',
        label: 'Mein Center macht nicht mit',
        id: 'reason1',
        value: 'Mein Center macht nicht mit',
        name: 'feedback'
    },
    reason2: {
        fieldType: 'checkbox',
        label: 'Die Gutscheine sprechen mich nicht an',
        id: 'reason2',
        value: 'Die Gutscheine sprechen mich nicht an',
        name: 'feedback'
    },
    reason3: {
        fieldType: 'checkbox',
        label: 'Ich benötige keine digitale Park-Funktion',
        id: 'reason3',
        value: 'Ich benötige keine digitale Park-Funktion',
        name: 'feedback'
    },
    reason4: {
        fieldType: 'checkbox',
        label: 'Sonstiges',
        id: 'reason4',
        value: 'Sonstiges',
        name: 'feedback'
    }
});

const isFormSubmitting = computed(() => isSubmitting.value || statusObj.value?.success);

const getFormClass = computed(() => {
    return {
        'is-submitting': isFormSubmitting.value
    }
})

const buttonConfig = ref({
    fieldType: 'submit',
    label: staticKeys.value?.account_form_delete_feedback_button_label'],
    active: isActive,
    visible: true,
    buttonType: 'primary',
    large: 'true',
    isPending: isFormSubmitting,
});

const showTextarea = computed(() => values.feedback?.includes('Sonstiges'));

const onSubmit = handleSubmit((values) => {
    if (values) {
        alert(JSON.stringify(values, null, 2));

        const string = values.feedback.join('|');
        const reasons = string?.substring(0, string.length);

        trackUserClick('User_Delete_Reasons', {
            center_id: centerUid.value,
            center_name: centerName.value,
            form_delete_reasons: reasons
        });
    }
});
*/

onUnmounted(() => {
    if (localStorage.getItem('showFeedbackForm')) {
        localStorage.removeItem('showFeedbackForm')
    }
})

</script>

<template>
    <SideBarWrapper class="feedback-note-container">
        <SideBarHeader>
            <SideBarMainHeadline :text="staticKeys?.account_form_delete_feedback_headline" />

            <p class="sidebar-delete-feedback-text" v-html="deleteIntro" />
        </SideBarHeader>

        <img :src="Illustration1" :alt="staticKeys?.general_error_headline">

        <!--
        <form class="sidebar-delete-feedback-form" :class="getFormClass" @submit.prevent="onSubmit">
            <FormSection>
                <FormCheckBoxGroup>
                    <FormCheckBox :config="checkboxConfig?.reason1"/>
                    <FormCheckBox :config="checkboxConfig?.reason2"/>
                    <FormCheckBox :config="checkboxConfig?.reason3"/>
                    <FormCheckBox :config="checkboxConfig?.reason4"/>
                </FormCheckBoxGroup>
            </FormSection>

            <Transition name="fade">
                <FormSection>
                    <FormTextarea v-if="showTextarea" :config="textareaConfig"/>
                </FormSection>
            </Transition>

            <FormButton :config="buttonConfig"/>
        </form>
        -->
    </SideBarWrapper>
</template>

<style lang="scss">
.feedback-note-container {
    display: flex;
    flex-flow: row wrap;
    height: 100%;

    .side-bar-header {
        width: 100%;
    }

    img {
        width: 100%;
        margin-top: auto;
    }
}

.sidebar-delete-feedback-text {
    @include body2-medium;

    a {
        @include textlink();
    }
}

.sidebar-delete-feedback-form {
    margin-top: 40px;

    &::after {
        content: '';
        transition: opacity 400ms ease;
        opacity: 0;
    }

    &.is-submitting {
        @include form-is-submitting;
    }
}
</style>
